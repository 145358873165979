<template>
  <div class="side-filter-card card">
    <div class="card-header">
      <h2 class="mb-0">
        <b-button v-b-toggle="'sliderFilter'" class="btn btn-link" variant="link">
          {{ $t(`search-result.${item.title}`) }}
        </b-button>
      </h2>
    </div>

    <b-collapse id="sliderFilter" v-model="isVisible">
      <b-card>
        <div class="card-body">
          <vue-slider
            v-model="value"
            direction="rtl"
            :min="min"
            :max="max"
            :marks="marks"
            :hideLabel="true"
            :included="true"
            :contained="true"
            tooltip="always"
            @change="change"
          ></vue-slider>
          <p v-if="lang === 'en'">
            {{ $t('search-result.from') }}-{{ getPriceWithSymbol(item.symbol, value[0]) }}
            {{ $t('search-result.to') }}-{{ getPriceWithSymbol(item.symbol, value[1]) }}
          </p>
          <p v-else>
            {{ $t('search-result.from') }}-{{ getPriceWithSymbol(item.symbol, value[0]) }}
            {{ $t('search-result.to') }}-{{ getPriceWithSymbol(item.symbol, value[1]) }}
          </p>
          <!-- :clickable="false" -->
        </div>
      </b-card>
    </b-collapse>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import VueSlider from 'vue-slider-component';
import { BButton, BCollapse, VBToggle, BCard } from 'bootstrap-vue';
import gMixin from '@/utils/mixins';

export default {
  name: 'SidebarCollapseItem',
  components: {
    BButton,
    BCollapse,
    BCard,
    VueSlider,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  mixins: [gMixin],
  props: {
    item: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      isVisible: true,
      value: this.item.value,
      min: this.item.min,
      max: this.item.max,
      marks: this.item.marks,
    };
  },
  computed: {
    ...mapGetters({
      lang: 'GET_LANGUAGE',
    }),
  },
  watch: {
    value() {
      // this.$emit('change', this.value)
    },
  },
  methods: {
    change() {
      this.$emit('change', this.value);
    },
  },
};
</script>

<style lang="sass">
$themeColor: #ff8b07 !default
$bgColor: #999 !default
@import 'vue-slider-component/lib/theme/material.scss'
</style>
<style scoped>
  .card-body{
    padding-bottom: 0.5rem;
  }
</style>
<style>
  .card.side-filter-card .card-header .btn{
    direction: ltr;
  }
</style>
